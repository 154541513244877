import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const MenuContext = createContext();


export const MenuProvider = ({children}) => {
    
    //
    const [listado, setListado] = useState([]);
    const [art, setArt] = useState([]);
    const [titulo, setTitulo] = useState('PRODUCTOS');
    const [variants, setVariants] = useState([]);


    
    const u = window.location.pathname;
    var res = u.substr(11);
    var res2 = u.substr(10);
    console.log(res);


    //
    function filterarray(a){
        var lista = JSON.parse(window.sessionStorage.getItem('items'));
        console.log(lista);
        if(a === "Mochilas"){
            const result = lista.filter(item => item.categories[0].id=== 46);
            console.log(result);
            setListado(result);
            setTitulo("MOCHILAS");
            console.log(listado);
        }
        else if(a === "Carteras"){
            const result = lista.filter(item => item.categories[0].id=== 42);
            console.log(result);
            setListado(result);
            setTitulo("CARTERAS");
            console.log(listado);
        }
        else if(a === "Cintos"){
            const result = lista.filter(item => item.categories[0].id=== 43);
            console.log(result);
            setListado(result);
            setTitulo("CINTOS");
            console.log(listado);
        }
        else if(a === "Gorras"){
            const result = lista.filter(item => item.categories[0].id=== 45);
            console.log(result);
            setListado(result);
            setTitulo("GORRAS");
            console.log(listado);
            console.log("Gorras");
        }
        else if(a === "Anillos"){
            const result = lista.filter(item => item.categories[0].id=== 41);
            console.log(result);
            setListado(result);
            setTitulo("ANILLOS");
            console.log(listado);
        }
        else if(a === "Collares"){
            const result = lista.filter(item => item.categories[0].id=== 44);
            console.log(result);
            setListado(result);
            setTitulo("COLLARES");
            console.log(listado);
        }
        else if(a === "Pulseras"){
            const result = lista.filter(item => item.categories[0].id=== 47);
            console.log(result);
            setListado(result);
            setTitulo("PULSERAS");
            console.log(listado);
        }
    }

    //
    function filterMochilas(){
        var lista = JSON.parse(window.sessionStorage.getItem('items'));
        const result = lista.filter(item => item.categories[0].id=== 46);
        console.log(result);
        setListado(result);
        setTitulo("MOCHILAS");
        console.log(listado);
    }

    //
    function filterCintos(){
        var lista = JSON.parse(window.sessionStorage.getItem('items'));
        const result = lista.filter(item => item.categories[0].id=== 43);
        console.log(result);
        setListado(result);
        setTitulo("CINTOS");
        console.log(listado);
    }

    //
    function filterGorras(){
        var lista = JSON.parse(window.sessionStorage.getItem('items'));
        const result = lista.filter(item => item.categories[0].id=== 45);
        console.log(result);
        setListado(result);
        setTitulo("GORRAS");
         console.log(listado);
    }

    //
    function filterCarteras(){
        var lista = JSON.parse(window.sessionStorage.getItem('items'));
        const result = lista.filter(item => item.categories[0].id=== 42);
        console.log(result);
        setListado(result);
        setTitulo("CARTERAS");
         console.log(listado);
    }

    //
    function filterAnillos(){
        var lista = JSON.parse(window.sessionStorage.getItem('items'));
        const result = lista.filter(item => item.categories[0].id=== 41);
        console.log(result);
        setListado(result);
        setTitulo("ANILLOS");
         console.log(listado);
    }

    //
    function filterCollares(){
        var lista = JSON.parse(window.sessionStorage.getItem('items'));
        const result = lista.filter(item => item.categories[0].id=== 44);
        console.log(result);
        setListado(result);
        setTitulo("COLLARES");
         console.log(listado);
    }

    //
    function filterPulseras(){
        var lista = JSON.parse(window.sessionStorage.getItem('items'));
        const result = lista.filter(item => item.categories[0].id=== 47);
        console.log(result);
        setListado(result);
        setTitulo("PULSERAS");
         console.log(listado);
    }

    //
    function filterVariantes(v){
        var lista = JSON.parse(window.sessionStorage.getItem('items'));
        const result = lista.filter(item => item.attributes[1].options[0] === v);
        const result2 = result.filter(item2 => item2.attributes[0].options[0] !== art[0].attributes[0].options[0]);
        console.log(result);
        console.log(result2);
        setVariants(result2);
    }

    //
    var colors = [];
    function checkVariantes(){
        for (let i = 0; i < variants.length; i++) {
            console.log(variants[i].attributes[0].options[0]);
            if(variants[i].attributes[0].options[0] == art[0].attributes[0].options[0]){
                console.log("ya existe ese color");
            }else{
                console.log("No existe ese color");
                colors.push(variants[i].attributes[0].options[0]);
            }
            console.log(colors);
          }
    }

    //
    function call(l){
        var lista = JSON.parse(window.sessionStorage.getItem('items'));
        var result = lista.filter(item => item.id == l);
        setArt(result);
        console.log(result);
        return art;
    }
    
    return(
        <MenuContext.Provider value={{call, art, titulo, listado, setListado, filterarray, filterAnillos, filterCarteras, filterCollares, filterGorras, filterPulseras, filterCintos, filterMochilas, filterVariantes, variants, checkVariantes, colors}}>

            {children}
        
        </MenuContext.Provider>
    )
}