import React, { useState, useEffect } from 'react';

import insta from './images/instagram.png';
import face from './images/facebook.png';

const Footer = () =>{


    return(

        <div>
            <div className="footer">
                <div className="foot_div">
                    <h4>COMPAÑÍA</h4>
                    <a>Nosotros</a>
                    <a>Términos y condiciones</a>
                    <a>Aviso legal</a>
                    <a href="https://app.kordata.mx/facturacion/cintosfreedom" target="_blank">Facturación electrónica</a>
                </div>
                <div className="foot_div2">
                    <h4>SÍGUENOS</h4>
                    <a href="https://www.instagram.com/cintosfreedommx/" target="_blank"><img src={insta} alt="Instagram"/></a>
                    <a href="https://www.facebook.com/Cintosfreedommx-101180884754016" target="_blank"><img src={face} alt="Facebook"/></a>
                </div>
                <div className="clear"></div>
            </div>
            <div className="copy_right">&copy; 2022 Cintos y accesorios L&L S.A. de C.V.<br/>Todos los derechos reservados.</div>
        </div>
        
    );

}

export default Footer;