import React, { useState, useEffect } from 'react';
import auth from '../firebase/firebaseConfig';
import axios from 'axios';

import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from "firebase/auth";


const Login = () =>{
    
    //
    const register = async() => {
        try{
        const user = createUserWithEmailAndPassword(auth, "jaasalanis@hotmail.com", "123456");
        console.log(user);
        }catch(error){
        console.log(error);
        }
    }

    //
    function logIn(){
        axios.post("https://fjs.de9.myftpupload.com/wp-json/cocart/v2/login")
        .then((response) => {
        // Successful request
        console.log("Response Status:", response.status);
        console.log("Response Headers:", response.headers);
        console.log("Response Data:", response.data);
        })
        .catch((error) => {
        // Invalid request, for 4xx and 5xx statuses
        console.log("Response Status:", error.response.status);
        console.log("Response Headers:", error.response.headers);
        console.log("Response Data:", error.response.data);
        })
        .finally(() => {
        // Always executed.
        });
    }

    return(

        <div className="content">
            <div className="log_area">
                <h3>LOGIN</h3>
                <div>
                    <label htmlFor="mail_log">EMAIL</label>
                    <input type="text" id="mail_log"/>
                </div>
                <div>
                    <label htmlFor="pass_log">PASSWORD</label>
                    <input type="password" id="pass_log"/>
                </div>
                <a className="recovery_link">¿Olvidó el password?</a>
                <button className="btn1" onClick={logIn}>ENTRAR</button>
                <a className="recovery_link">Registrarse</a>
            </div>
        </div>
        
    );

}

export default Login;