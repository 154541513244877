import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import InnerImageZoom from 'react-inner-image-zoom';
import axios from 'axios';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import { CartContext } from '../context/cartcontext';
import { MenuContext } from '../context/menucontext';

const WooCommerceRestApi = require("@woocommerce/woocommerce-rest-api").default;


const Producto = () =>{

    
    //  
    const { addCart } = useContext(CartContext);
    const { call, filterVariantes, variants, art, checkVariantes } = useContext(MenuContext);

    //
    const [ready, setReady] = useState(false);

    //
    const u = window.location.pathname;
    var res = u.substr(10);
    console.log(res);
  
    //
    const [quantity, setQuantity] = useState();
    
    
    useEffect(() => {

      function retq(){
        return new Promise((resolve, reject) =>{
          resolve(
            call(res)
          );
          return art;
        })
      }

      retq()
      .then(resp => {
          setReady(true);
          filterVariantes(resp[0].attributes[1].options[0]);
      })
      
      .catch((error) => {
          console.log(error);
      });

    }, [ready]);

    
    //
    function back(){
        window.location.href = "/Productos/" + art[0].categories[0].name;
    }

    //
    function reloadP(){
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
   
	   
    return(
        <div className="product_area"> 
                {
                    art.length === 0
                    ?
                    <div>CARGANDO</div>
                    :
                <div>
                    <div className="product_im">
                    <InnerImageZoom src={art[0].images[0].src} zoomSrc={art[0].images[0].src}/>
                        
                    </div>
                    <div className="product_txt">
                        <a className="linkcateg" onClick={back}>&larr;{art[0].categories[0].name}</a>
                        <p className="product_model"><span className="span_desc">Modelo: </span>{art[0].attributes[1].options[0]}</p>
                        <h1 id="product_tt">{art[0].name.toUpperCase()}</h1>
                        <p className="product_detail"><span className="span_desc">Color: </span>{art[0].attributes[0].options[0]}</p>
                        <div>
                          <p className="colorsTitle">Otros colores:</p>
                          {
                            variants.length === 0
                            ?
                            <p className="colorLink">Color único</p>
                            :
                            variants.map(v => ( 
                              <Link key={v.id} to={"/Producto/" + v.id} onClick={reloadP} className="colLink">
                                  <p className="colorLink">{v.attributes[0].options[0]}</p>
                              </Link>
                            ))
                          }
                        </div>
                        <p className="product_price">{"$"+art[0].price + " MXN"}</p>
                        <div className="box_stars">
                            <p className="rating_stars">&#9733; &#9733; &#9733; &#9733; &#9733;</p>
                        </div>
                        {/*<div>
                          <label htmlFor="quantity" className="quant_box">CANTIDAD: </label>
                          <input
                            type="number"
                            id="quantity"
                            name="quantity"
                            min="1" max="5"
                            placeholder="1"
                            onChange={e => setQuantity(e.target.value)}
                          />
                        </div>*/}
                        {/*<button className="btn2" onClick={()=> addCart(res, quantity)}>AÑADIR</button>*/}
                        <div className="box_desc">
                            <p className="desc_title">DESCRIPCIÓN</p>
                            <p id="product_desc">{art[0].description.replace(/(<([^>]+)>)/gi, "")}</p>
                        </div>
                    </div>
                    <div className="clear"></div>
                </div>
                }    
        </div>
    );
}

export default Producto;