import React, { useState, useEffect, useContext } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { MenuContext } from '../context/menucontext';

import logo from './images/logo.png';
import menu_icon from './images/menu_icon.png';

const Menu = () =>{

    const { filterMochilas, filterCintos, filterAnillos, filterCollares, filterGorras, filterPulseras, filterCarteras } = useContext(MenuContext);
    
    function reloadPage(){
        setTimeout(() => {
            window.location.reload();
          }, 100);
    }

    function displayMenu(){
        var b = document.getElementById("menu_open");

        if (b.style.display == "block"){
            b.style.display = "none";
        }else{
            b.style.display = "block"
        }
    }
    
    return(

        <div className="menu">
            <div className="logo">
                <Link to="/"><img src={logo} alt="Logo"/></Link>
            </div>
            <div className="menu_icon" id="menu_btn" onClick={displayMenu}>
                <img src={menu_icon} alt="Menu"/>
            </div>
            <div className="links" id="menu_open">
                <Link className="link" to="/Productos/Cintos" onClick={filterCintos}>Cintos</Link>
                <Link className="link" to="/Productos/Mochilas" onClick={filterMochilas}>Mochilas</Link>
                <Link className="link" to="/Productos/Accesorios" onClick={filterGorras}>Gorras</Link>
                <Link className="link" to="/Productos/Accesorios" onClick={filterCarteras}>Carteras</Link>
                <Link className="link" to="/Productos/Accesorios" onClick={filterPulseras}>Pulseras</Link>
                <Link className="link" to="/Productos/Accesorios" onClick={filterCollares}>Collares</Link>
                <Link className="link" to="/Productos/Accesorios" onClick={filterAnillos}>Anillos</Link>
            </div>
            
        </div>
    );

}

export default Menu;