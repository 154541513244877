import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import cover1 from './images/cover1.jpg';
import cover2 from './images/cover2.jpg';
import cover3 from './images/cover3.jpg';
import cover4 from './images/cover4.jpg';
import cover5 from './images/cover5.jpg';
import categIm1 from './images/cat1.jpg';
import categIm2 from './images/cat2.jpg';
import categIm3 from './images/cat3.jpg';
import categIm4 from './images/cat4.jpg';
import categIm5 from './images/cat5.jpg';
import categIm6 from './images/cat6.jpg';
import categIm7 from './images/cat7.jpg';

const WooCommerceRestApi = require("@woocommerce/woocommerce-rest-api").default;


const Home = () =>{

    const [lista, setLista] = useState(JSON.parse(window.sessionStorage.getItem('items')));
  
    //
    useEffect(() => {
        if(lista === null){
            call();
        }
        else{
            console.log("lista de productos cargada");
        }
    }, []);


    //
    const api = new WooCommerceRestApi({
        url: "https://tienda.cintosfreedom.com/backend",
        consumerKey: process.env.REACT_APP_WOOCOMMERCE_CONSUMERKEY,
        consumerSecret: process.env.REACT_APP_WOOCOMMERCE_CONSUMERSECRET,
        version: "wc/v3",
        queryStringAuth: true,
        axiosConfig: {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json', 
                "Authorization" : 'Basic'
            }
          }
    });

    //
    async function call(){
        try{
            const response = await api.get("products?per_page=20");
            console.log(response.data);
            window.sessionStorage.setItem('items', JSON.stringify(response.data));
            setLista(JSON.parse(window.sessionStorage.getItem('items')));
            console.log(lista)
        }catch(error){
            console.log(error);
        }	   
    }

      
    return(

        <div className="content">
            <div className="cover">
                {/*<img src={cover} alt="cover"/>
                <div className="cover_data">
                    <h1>Gran variedad de cinturones</h1>
                    <Link className="cover_btn" to="/Productos/Cintos">CINTOS &#8594;</Link>
                </div>*/}
                <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
                <div>
                    <img src={cover1} alt="Cover 1"/>
                    <Link className="cover_btn2" to="/Productos/Cintos">CINTOS &#8594;</Link>
                </div>
                <div>
                    <img src={cover2} alt="Cover 2"/>
                    <Link className="cover_btn2" to="/Productos/Carteras">CARTERAS &#8594;</Link>
                </div>
                <div>
                    <img src={cover3} alt="Cover 3"/>
                    <Link className="cover_btn2" to="/Productos/Mochilas">MOCHILAS &#8594;</Link>
                </div>
                <div>
                    <img src={cover4} alt="Cover 4"/>
                    <Link className="cover_btn2" to="/Productos/Carteras">CARTERAS &#8594;</Link>
                </div>
                <div>
                    <img src={cover5} alt="Cover 5"/>
                    <Link className="cover_btn2" to="/Productos/Gorras">GORRAS &#8594;</Link>
                </div>
                </Carousel>
            </div>

            <div className="categs">
                <Link to="/Productos/Cintos" className="categ">
                <img src={categIm1} alt="Categoría"/>
                    <p className="linkcateg">Cintos &#8594;</p>
                </Link>
                <Link to="/Productos/Mochilas" className="categ">
                <img src={categIm2} alt="Categoría"/>
                    <p className="linkcateg">Mochilas &#8594;</p>
                </Link>
                <Link to="/Productos/Gorras" className="categ">
                <img src={categIm3} alt="Categoría"/>
                    <p className="linkcateg">Gorras &#8594;</p>
                </Link>
                <Link to="/Productos/Carteras" className="categ2">
                <img src={categIm4} alt="Categoría"/>
                    <p className="linkcateg">Carteras &#8594;</p>
                </Link>
                <Link to="/Productos/Pulseras" className="categ2">
                <img src={categIm5} alt="Categoría"/>
                    <p className="linkcateg">Pulseras &#8594;</p>
                </Link>
                <Link to="/Productos/Collares" className="categ2">
                <img src={categIm6} alt="Categoría"/>
                    <p className="linkcateg">Collares &#8594;</p>
                </Link>
                <Link to="/Productos/Anillos" className="categ2">
                <img src={categIm7} alt="Categoría"/>
                    <p className="linkcateg">Anillos &#8594;</p>
                </Link>
                <div className="clear"></div>
            </div>

            {
            lista === null
            ?
            <div className="load_msg">CARGANDO...</div>
            :
            <div className="newitems">
                <h3>LO NUEVO</h3>
                {lista.slice(0, 6).map(art => ( 
                    <Link key={art.id} to={"Producto/" + art.id}>
                        <div className="cat">
                        <img src={art.images[0].src} alt="Categoría"/>
                        <p className="linkcat">{art.name}</p>
                        </div> 
                    </Link>  
                ))}
                <div className="clear"></div>
            </div>
            }
        
        </div>
    );

}

export default Home;