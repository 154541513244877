import React, { useState, useEffect, useContext } from 'react';
import { NavLink, Link } from 'react-router-dom';
import axios from 'axios';

import location from './images/location.png';
import bag from './images/bag.png';

import { CartContext } from '../context/cartcontext';

const Top = () =>{
    

    //const { numkey, getCount } = useContext(CartContext);

    //
    const u = window.location.pathname;
    var res = u.substr(10);

    //
    const [results, setResults] = useState ([]);

    //
    function searching(){
        var lista = JSON.parse(window.sessionStorage.getItem('items'));
        var a = document.getElementById("buscar").value.toLowerCase();
        if (a.length >= 3){
            document.getElementById("result_window").style.display = "block";
            var x =  lista.filter(x => {
                return x.tags[0].name.toLowerCase().includes(a) || x.slug.toLowerCase().includes(a);
            });

            console.log(x);
            setResults(x);
        } else{
            document.getElementById("result_window").style.display = "none";
        }
    }

     //
     useEffect(() => {
       // getCount();
    }, []);


    //
    function closeSearch(){
        document.getElementById("result_window").style.display = "none";
    }

    //
    function reloadSearch(){
        setTimeout(() => {
            window.location.reload();
          }, 100);
    }


    
    return(

        <div className="top">
            <div className="location">
                <NavLink to="/Locacion" className={({ isActive }) =>isActive ? 'activeLink' : 'top_link'}>
                    <img className="ico_top" src={location} alt=""/> Tienda Física
                </NavLink>
            </div>
            <div className="searchbar">
                <input 
                  type="text"
                  id="buscar"
                  placeholder="Buscar..."
                  onChange={searching}/>
            </div>
            <div id="result_window">
                <button className="close_icon" onClick={closeSearch}>&times;</button>
                <h5>Resultados de la búsqueda</h5>
                {
                    results.length === 0
                    ?
                    <div>Sin resultados</div>
                    :
                    <div>
                    {results.map(art => ( 
                        <Link key={art.id} to={"Producto/" + art.id} onClick={reloadSearch}>
                            <div className="cat_search">
                                <img src={art.images[0].src} alt="Categoría"/>
                                <p className="linkcat_search">{art.name}</p>
                            </div> 
                            <div className="clear"></div>
                        </Link>  
                    ))}
                    </div>
                }
            </div>
            <div className="access">
                <div>
                    <NavLink to="/"><p className="top_link">Login</p></NavLink>
                    <NavLink to="/"><p className="top_link">Registro</p></NavLink>
                    <NavLink className="cart_num" to="/">
                        <img className="ico_top2" src={bag} alt="Carrito"/>
                        {/*<div className="quantity_num">{numkey}</div>*/}
                    </NavLink>
                </div>
            </div>
        </div>
    );

}

export default Top;