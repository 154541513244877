import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {useFormik, ErrorMessage} from 'formik';
import auth from '../firebase/firebaseConfig';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from "firebase/auth";

const WooCommerceRestApi = require("@woocommerce/woocommerce-rest-api").default;

const Registro = () =>{

    //fields
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [username, setUsername] = useState('');
    const [pass, setPass] = useState('');



    //
    const registerUser = async() => {
        try{
        const user = createUserWithEmailAndPassword(auth, "jaasalanis@hotmail.com", "123456");
        console.log(user);
        }catch(error){
        console.log(error);
        }
    }

    //
    const api = new WooCommerceRestApi({
        url: "https://fjs.de9.myftpupload.com/",
        consumerKey: process.env.REACT_APP_WOOCOMMERCE_CONSUMERKEY,
        consumerSecret: process.env.REACT_APP_WOOCOMMERCE_CONSUMERSECRET,
        version: "wc/v3",
        axiosConfig: {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json', 
                'Authorization': 'Basic'
            }
          }
    });

  //
    const user = {
      "email": email,
      "first_name": name,
      "last_name": lastname,
      "username": username,
      "password": pass,
      "confirm_password": pass
    }
    
    //
    function addClient(){
        console.log(user);
        api.post("customers", user)
        .then(result => {
          console.log(result); 
        })
        .catch((error) => {
           console.log(error)
        })
        .finally(() => {
            // Always executed.
        });
    }


    return(

        <div className="content">
            <div className="log_area">
                <h3>CREAR CUENTA</h3>
                  <form>
                    <p className="vacio">error</p>
                    <div>
                        <label htmlFor="name_log">NOMBRE</label>
                        <input 
                          type="text" 
                          id="name_log"
                          name="first_name"
                          onChange={e => setName(e.target.value)}
                        />
                    </div>
                    <div>
                        <label htmlFor="lastname_log">APELLIDO</label>
                        <input
                          type="text"
                          id="lastname_log"
                          name="last_name"
                          onChange={e => setLastname(e.target.value)}
                        />
                    </div>
                    <div>
                        <label htmlFor="lastname_log">USERNAME</label>
                        <input
                          type="text"
                          id="username_log"
                          name="username"
                          onChange={e => setUsername(e.target.value)}
                        />
                    </div>
                    <div>
                        <label htmlFor="mail_log">EMAIL</label>
                        <input
                          type="email"
                          id="mail_log"
                          name="email"
                          onChange={e => setEmail(e.target.value)}
                        />
                    </div>
                    <div>
                        <label htmlFor="pass_log">PASSWORD</label>
                        <input
                          type="password"
                          id="pass_log"
                          name="password"
                          onChange={e => setPass(e.target.value)}
                        />
                    </div>
                  </form>

                <button className="btn1" onClick={addClient}>REGISTRARSE</button>
            </div>
        </div>
        
    );

}

export default Registro;