import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';



const Locacion = () =>{

    return(

        <div className="content">
            <div className="centerElem">
                <Link className="linkcateg" to="/">&larr;Home</Link>
                <h3>TIENDA FÍSICA</h3>
                <h1>Cintos y accesorios L&L S.A. de C.V.</h1>
                <p>Ave. Chapultepec #424, Colonia Caracol, Monterrey N.L. CP: 64810</p>
                <a className="phonelink" href="tel:8181901900">TEL: 81 8190 1900</a>
                <a className="phonelink" href="mailto:cintoslyl@cintosfreedom.com">cintoslyl@cintosfreedom.com</a>
            </div>
        </div>
    );
}

export default Locacion;