import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import { MenuContext } from '../context/menucontext';

const Productos = () =>{

    //
    const { listado, setListado, filterarray, titulo } = useContext(MenuContext);
    console.log(listado);

    //
    const u = window.location.pathname;
    var res = u.substr(11);

    //
     useEffect(() => {
       filterarray(res);
      }, []);

    return(

        <div className="content">
            {
                listado === null
                ?
                <div className="load_msg">CARGANDO</div>
                :
                <div className="newitems">
                    <Link className="linkcateg" to="/">&larr;Home</Link>
                    <h3>{titulo}</h3>
                    {listado.map(art => ( 
                        <Link key={art.id} to={"/Producto/" + art.id}>
                            <div className="cat">
                            <img src={art.images[0].src} alt="Categoría"/>
                            <p className="linkcateg">{art.name}</p>
                            </div> 
                        </Link>  
                    ))}
                    <div className="clear"></div>
                </div>
            }
        </div>
    );
}

export default Productos;