import React, {useContext} from 'react';
import { Routes, Route } from 'react-router-dom';

import { CartProvider } from './context/cartcontext.js';
import { MenuProvider } from './context/menucontext.js';

import './assets/style.css';

import Top from './assets/top.js';
import Footer from './assets/footer.js';
import Menu from './assets/menu.js';
import Home from './assets/home.js';
import Productos from './assets/productos.js';
import Producto from './assets/producto.js';
import Locacion from './assets/locacion.js';
import Login from './assets/login.js';
import Registro from './assets/registro.js';
import Carrito from './assets/carrito.js';


function App() {
  return (
    <MenuProvider>
      <CartProvider>
        <div>
          <Top/>
          <Menu/>
          <Routes>
              <Route exact path="/" element={<Home/>}/>
              <Route exact path="/Productos/:id" element={<Productos/>}/>
              <Route exact path="/Producto/:id" element={<Producto/>}/>
              <Route exact path="/Locacion" element={<Locacion/>}/>
              <Route exact path="/Login" element={<Login/>}/>
              <Route exact path="/Registro" element={<Registro/>}/>
              <Route exact path="/Carrito" element={<Carrito/>}/>
          </Routes>
          <Footer/>
        </div>
      </CartProvider>
    </MenuProvider>
  );
}

export default App;
