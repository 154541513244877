import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { CartContext } from '../context/cartcontext';

const WooCommerceRestApi = require("@woocommerce/woocommerce-rest-api").default;


const Carrito = () =>{

    //
    const { quitItem, totalcart, getCart, listado } = useContext(CartContext); 
    console.log(listado);

    //
    const [basket, setBasket] = useState([]);

    
    useEffect(() => {
        getCart();
    }, []);

    //
    function getCar(){
      getCart();
    }



    return(

        <div className="content">
            {
             listado == undefined
                ?
                <div className="load_msg">CARGANDO...</div>
                :
                <div className="newitems">
                    <Link className="linkcateg" to="/">&larr;Home</Link>
                    <h3>Carrito</h3>
                    {listado.map(art => ( 
                            <div className="cart_item" key={art[1].id}>
                                <img className="cart_item_im" src={art[1].featured_image} alt="Categoría"/>
                                <div className="cart_item_data">
                                  <p className="cart_item_name">{art[1].name}</p>
                                  <p>Precio unitario: $ {(Math.round(art[1].price / 100)).toFixed(2)}</p>
                                  <p>Piezas: {art[1].quantity.value}</p>
                                  <p>Total: $ {(art[1].totals.total).toFixed(2)}</p>
                                  <button className="delete_item_btn" onClick={() => quitItem(art[1].item_key)}>QUITAR</button>
                                </div>
                                <div className="clear"></div>
                            </div>
                    ))}
                    <div className="cart_totals">
                      <p>TOTAL: $ {(parseInt(totalcart)/100).toFixed(2)}</p>   
                      <button className="btn1">PAGAR</button>   
                    </div>
                </div>
                    }
           
        </div>
    );
}

export default Carrito;