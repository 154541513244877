import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const CartContext = createContext();
const WooCommerceRestApi = require("@woocommerce/woocommerce-rest-api").default;


export const CartProvider = ({children}) => {
    
    var kc = window.sessionStorage.getItem("keycart");
    console.log(kc);

    const [keycartbag, setKeycartbag] = useState(kc);
    const [listado, setListado] = useState([]);
    const [numkey, setNumkey] = useState('0');
    const [totalcart, setTotalCart] = useState('0');


    //
    function addCart(res, quantity){
      if(kc === null){
        axios.post("https://fjs.de9.myftpupload.com/wp-json/cocart/v2/cart/add-item", {
          id: res,
          quantity: quantity,
        })
        .then(result => {
          console.log(result.data);
          //setKeycartbag(result.data.cart_key);
          console.log("Key is new")
          window.sessionStorage.setItem("keycart", result.data.cart_key);
          setTimeout(() => {
            getCount();
          }, 200);
        }).catch((error) => {
            console.log(error);
          })
          .finally(() => {
            kc =  window.sessionStorage.getItem("keycart");
        });   
      }else{
        axios.post('https://fjs.de9.myftpupload.com/wp-json/cocart/v2/cart/add-item?cart_key=' + kc, {
          id: res,
          quantity: quantity,
        })
        .then(result => {
          console.log(result.data);
          setTimeout(() => {
            getCount();
          }, 200);
        }).catch((error) => {
            console.log(error);
          })
          .finally(() => {
            console.log(keycartbag);
        });   
      }
    }
    
    //
    function getCart(){
        axios.get('https://fjs.de9.myftpupload.com/wp-json/cocart/v2/cart/items?cart_key=' + kc)
        .then((response) => {
            // Successful request
            console.log("Response Status:", response.status);
            console.log("Response Headers:", response.headers);
            console.log(response.data);
            if(response.data != 'No items in the cart.'){
                setListado(Object.entries(response.data));
                //setListado(response.data);
                console.log(listado);
            }else{
                console.log(listado);
            }
          })
          .catch((error) => {
            // Invalid request, for 4xx and 5xx statuses
            console.log("Response Status:", error.response.status);
            console.log("Response Headers:", error.response.headers);
            console.log("Response Data:", error.response.data);
          })
          .finally(() => {
            // Always executed.
          });
    }
    
    //
    function getCount(){
        axios.get('https://fjs.de9.myftpupload.com/wp-json/cocart/v2/cart?cart_key=' + kc)
        .then((response) => {
            console.log(response.data);
            setNumkey(response.data.item_count);
            setTotalCart(response.data.totals.total);
          })
          .catch((error) => {
            console.log("Response Data:", error.response.data);
          })
          .finally(() => {
            // Always executed.
          });
    }

  
      //
      function quitItem(k){
          axios.delete("https://fjs.de9.myftpupload.com/wp-json/cocart/v2/cart/item/" + k + "?cart_key=" + kc)
          .then(result => {
            console.log(result.data);
          }).catch((error) => {
              console.log(error);
            })
            .finally(() => {
              getCart();
              getCount();
            });
          
      }
    

    
    return(
        <CartContext.Provider value={{ numkey, setNumkey, keycartbag, setKeycartbag, getCount, addCart, getCart, quitItem, totalcart, listado}}>

            {children}
        
        </CartContext.Provider>
    )
}